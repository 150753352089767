import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import Type from "../components/typography/Type"

const IndexPage = () => {
  return (
    <Layout>
    <Container>
    <div class="w-full lg:w-1/2">
    <Type type="h1">Map your digital ecosystem</Type>
    <Type type="p4">We create benchmarks of digital journeys to help you compare your competitors and create better experiences.</Type>
    <div className="mt-8"><a href="/journeys"><button className="rounded-md font-bold px-6 py-2 bg-green border border-green text-white hover:bg-green/0 hover:text-green">Start</button></a>
    </div>
    </div>
   </Container>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Benchmark</title>

